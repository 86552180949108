@tailwind base;
@tailwind components;
@tailwind utilities;

.toggle-bg:after {
  content: '';
  @apply absolute left-0.5 top-0.5 bg-white border border-gray-300 h-5 w-5 rounded-full ring-0 transition;
}

input:checked + .toggle-bg:after {
  transform: translateX(100%);
  @apply border-white;
}

input:checked + .toggle-bg {
  @apply bg-cyan-600 border-cyan-600;
}

[type=checkbox], [type=radio]{
  @apply text-cyan-600;
}
/* chart styles */
.apexcharts-tooltip {
  @apply bg-white text-gray-500 border-0 rounded-lg shadow-lg !important;
}

.apexcharts-tooltip .apexcharts-tooltip-title {
  @apply py-2 px-4 bg-gray-100 border-b border-gray-200 !important;
}

.apexcharts-xaxistooltip {
  @apply text-gray-500 border-0 bg-white rounded-lg shadow-lg !important;
}

.apexcharts-xaxistooltip-text {
  @apply font-medium text-sm !important;
}

.apexcharts-xaxistooltip:before,
.apexcharts-xaxistooltip:after {
  @apply border-0 !important;
}

/* SVG map styles */
.svgMap-map-wrapper {
  @apply bg-white !important;
}

.svgMap-map-controls-wrapper {
  @apply shadow-none left-0 bottom-0 !important;
}

.svgMap-map-wrapper .svgMap-control-button  {
  @apply rounded-lg border-solid border border-gray-300 hover:bg-gray-100 !important;
}

.svgMap-map-wrapper .svgMap-control-button:first-child {
  @apply mr-2 !important;
}

.svgMap-tooltip {
  @apply bg-white shadow-lg rounded-lg border-0 text-left !important;
}

.svgMap-tooltip .svgMap-tooltip-content-container .svgMap-tooltip-flag-container {
  @apply inline-block mr-2 text-left border-0 p-0 !important;
}

.svgMap-tooltip .svgMap-tooltip-content-container .svgMap-tooltip-flag-container .svgMap-tooltip-flag {
  @apply inline-block border-0 h-4 p-0 !important;
}

.svgMap-tooltip .svgMap-tooltip-title {
  @apply inline-block pt-2 text-gray-900 font-semibold text-sm !important;
}

.svgMap-tooltip .svgMap-tooltip-content {
  @apply mt-0 !important;
}

.svgMap-tooltip .svgMap-tooltip-content table td {
  @apply text-sm text-left text-gray-500 font-normal !important;
}

.svgMap-tooltip .svgMap-tooltip-content table td span {
  @apply text-sm text-left text-gray-900 font-semibold !important;
}

.svgMap-tooltip .svgMap-tooltip-pointer {
  @apply hidden !important;
}

/* kanban styles */

.drag-card {
  @apply rotate-6 opacity-100 !important;
}

.ghost-card {
  @apply bg-gray-100 opacity-40 !important;
}

/* calendar styles */

.fc .fc-toolbar {
  @apply flex-row-reverse justify-end px-4 !important;
}

.fc .fc-toolbar.fc-header-toolbar {
  @apply mb-5 !important;
}

.fc .fc-toolbar-title {
  @apply text-lg text-gray-900 font-semibold !important;
}

.fc .fc-today-button {
  @apply rounded-lg border border-gray-200 bg-white text-sm font-medium px-4 py-2 text-gray-900 hover:bg-gray-100 hover:text-cyan-600 focus:z-10 focus:ring-2 focus:ring-cyan-200 focus:text-cyan-600 !important;
}

.fc-direction-ltr .fc-toolbar > * > :not(:first-child) {
  @apply mx-2 !important;
}

.fc .fc-button-group .fc-prev-button,
.fc .fc-button-group .fc-next-button {
  @apply bg-white border-0 text-gray-500 hover:text-gray-900 cursor-pointer p-2 hover:bg-gray-100 rounded inline-flex focus:bg-gray-100 focus:ring-1 focus:ring-gray-100 justify-center !important;
}

.fc .fc-scrollgrid {
  @apply border-l-0 border-gray-200 !important;
}

.fc .fc-daygrid-day-frame {
  @apply border-gray-200 !important;
}

.fc .fc-col-header-cell-cushion {
  @apply py-3 text-base text-gray-900 font-semibold !important;
}

.fc-theme-standard th {
  @apply border-0 border-b border-gray-200 !important;
}

.fc-direction-ltr .fc-daygrid-event.fc-event-end {
  @apply mr-2 !important;
}

.fc-direction-ltr .fc-daygrid-event.fc-event-start {
  @apply ml-2 !important;
}

.fc .fc-event .fc-event-main {
  @apply p-2 bg-cyan-600 hover:bg-cyan-700 !important;
}

.fc .fc-h-event .fc-event-main-frame {
  @apply text-xs font-semibold !important;
}

.fc .fc-daygrid-day-frame {
  @apply hover:bg-gray-50 cursor-pointer !important;
}